/*  FONTS
========================================== */
/*  VARIABLES
========================================== */
:root {
  --color-main: black;
  --color-link: #ff7300;
  --breakpoint-lg: 992px;
}

/*  SASS VARIABLES
========================================== */
/*  MIXINS
========================================== */
@media only screen and (max-width: 1200px) {
  body {
    background-color: transparent;
  }
  .header__hamb {
    display: block;
  }
}
body {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  background-color: transparent !important;
}
body .bodyWrap {
  width: 100%;
  height: 100%;
}

.contact-us {
  color: var(--color-link) !important;
}

.pageTitile {
  font-size: 60px;
  font-weight: 300;
  padding-bottom: 75px;
}
@media (max-width: 991px) {
  .pageTitile {
    font-size: 30px;
    padding-bottom: 30px;
    margin: 0;
  }
}

.iconLangMobile {
  text-transform: uppercase;
  color: var(--color-link);
  position: fixed;
  top: 12px;
  right: 80px;
  z-index: 11;
  font-size: 25px;
}
@media (min-width: 991px) {
  .iconLangMobile {
    display: none;
  }
}

h3 {
  font-family: Roboto Slab;
  font-size: 30px;
  font-weight: 400;
  line-height: 50px;
  margin-top: 40px;
}
@media (max-width: 991px) {
  h3 {
    font-size: 20px;
    line-height: 32px;
  }
}

.text-middle {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}
.text-middle strong {
  font-weight: 600;
}

p.text-middle {
  margin: 20px 0 0;
}

.page {
  margin-top: 67px;
}

.page-row {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 10px;
}
.page-row.two-col {
  grid-template-columns: repeat(2, minmax(50%, 1fr));
}
@media (max-width: 991px) {
  .page-row.two-col {
    grid-template-columns: 1fr;
  }
}

.icons-bg {
  padding-left: 40px;
  background-position: left center;
  background-size: contain;
  background-size: 30px;
  background-repeat: no-repeat;
  background-image: url("../img/mail.svg");
}
.icons-bg.icon-img-mail {
  background-image: url("../img/mail.svg");
}
.icons-bg.icon-telegram-light {
  background-image: url("../img/icon-telegram-light.svg");
}
.icons-bg.icon-check-list {
  background-image: url("../img/icon-check-list.svg");
}

.contacts-heading {
  display: block;
  width: 100%;
  font-size: 80px;
  line-height: 91px;
  font-family: Roboto Slab;
  padding-bottom: 10px;
  font-weight: 300;
}
@media (max-width: 991px) {
  .contacts-heading {
    font-size: 30px;
    line-height: 40px;
  }
  .contacts-heading > div {
    display: inline;
    width: inherit;
  }
}
.contacts-heading > div {
  width: 100%;
}
.contacts-heading strong {
  font-weight: 500;
}
.contacts-heading :last-child {
  text-align: right;
}

.anim1 {
  -webkit-animation-name: LR;
  animation-name: LR;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.06, -0.01, 0.17, 0.99);
}

.opa0 {
  -webkit-animation-name: o0;
  animation-name: o0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.anim2 {
  -webkit-animation-name: LR;
  animation-name: LR;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: cubic-bezier(0.16, 0.4, 0.37, 1);
  animation-timing-function: cubic-bezier(0.16, 0.4, 0.37, 1);
}

.anim3 {
  -webkit-animation-name: LR;
  animation-name: LR;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: cubic-bezier(0.16, 0.4, 0.37, 1);
  animation-timing-function: cubic-bezier(0.16, 0.4, 0.37, 1);
}

.anim4 {
  -webkit-animation-name: LR;
  animation-name: LR;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: cubic-bezier(0.16, 0.4, 0.37, 1);
  animation-timing-function: cubic-bezier(0.16, 0.4, 0.37, 1);
}

@-webkit-keyframes sides {
  0% {
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes sides {
  0% {
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes LR {
  0% {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@keyframes LR {
  0% {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@-webkit-keyframes o0 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes o0 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*  FONTS
========================================== */
/*  MIXINS
========================================== */
.anim1 {
  -webkit-animation-name: LR;
  animation-name: LR;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: cubic-bezier(0.06, -0.01, 0.17, 0.99);
  animation-timing-function: cubic-bezier(0.06, -0.01, 0.17, 0.99);
}

.opa0 {
  -webkit-animation-name: o0;
  animation-name: o0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.anim2 {
  -webkit-animation-name: LR;
  animation-name: LR;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: cubic-bezier(0.16, 0.4, 0.37, 1);
  animation-timing-function: cubic-bezier(0.16, 0.4, 0.37, 1);
}

.anim3 {
  -webkit-animation-name: LR;
  animation-name: LR;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: cubic-bezier(0.16, 0.4, 0.37, 1);
  animation-timing-function: cubic-bezier(0.16, 0.4, 0.37, 1);
}

.anim4 {
  -webkit-animation-name: LR;
  animation-name: LR;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: cubic-bezier(0.16, 0.4, 0.37, 1);
  animation-timing-function: cubic-bezier(0.16, 0.4, 0.37, 1);
}

@keyframes sides {
  0% {
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes LR {
  0% {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@keyframes o0 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #b7ac7f;
  opacity: 0;
  visibility: hidden;
}

.overlay.show {
  opacity: 0.8;
  visibility: visible;
}

.burgerMobile,
.btnCloseContacts {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 20px;
  right: 20px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.burgerMobile {
  display: none;
  z-index: 12;
}
@media (max-width: 991px) {
  .burgerMobile {
    top: 5px;
    display: block;
  }
}

@media (max-width: 991px) {
  .btnCloseContacts {
    display: none;
  }
}

.burgerMobile span,
.btnCloseContacts span {
  position: relative;
  margin-top: 9px;
  margin-bottom: 9px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}

.burgerMobile span,
.burgerMobile span::before,
.burgerMobile span::after,
.btnCloseContacts span,
.btnCloseContacts span::before,
.btnCloseContacts span::after {
  display: block;
  width: 30px;
  height: 3px;
  background-color: var(--color-link);
  outline: 1px solid transparent;
  transition-property: background-color, transform;
  transition-property: background-color, transform, -webkit-transform;
  transition-duration: 0.3s;
}

.burgerMobile span::before,
.burgerMobile span::after,
.btnCloseContacts span::before,
.btnCloseContacts span::after {
  position: absolute;
  content: "";
}

.burgerMobile span::before,
.btnCloseContacts span::before {
  top: -9px;
}

.burgerMobile span::after,
.btnCloseContacts span::after {
  top: 9px;
}

.burgerMobile.clicked span,
.btnCloseContacts.clicked span {
  background-color: transparent;
}

.burgerMobile.clicked span::before,
.btnCloseContacts.clicked span::before {
  -webkit-transform: translateY(9px) rotate(45deg);
  -ms-transform: translateY(9px) rotate(45deg);
  transform: translateY(9px) rotate(45deg);
}

.burgerMobile.clicked span::after,
.btnCloseContacts.clicked span::after {
  -webkit-transform: translateY(-9px) rotate(-45deg);
  -ms-transform: translateY(-9px) rotate(-45deg);
  transform: translateY(-9px) rotate(-45deg);
}

.burgerMobile.clicked span:before,
.burgerMobile.clicked span:after,
.btnCloseContacts.clicked span:before,
.btnCloseContacts.clicked span:after {
  background-color: var(--color-link);
}

.burgerMobile:hover,
.btnCloseContacts:hover {
  cursor: pointer;
}

/*  NAV
========================================== */
nav {
  background-color: black;
  position: fixed;
  z-index: 12;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  padding: 60px 40px 60px 40px;
  overflow-y: auto;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: -webkit-transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: -webkit-transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

nav.show {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

nav.show ul.main li {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
}

nav.show ul.main li:nth-child(1) {
  -webkit-transition-delay: 0.15s;
  -o-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

nav.show ul.main li:nth-child(2) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

nav.show ul.main li:nth-child(3) {
  -webkit-transition-delay: 0.45s;
  -o-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

nav.show ul.main li:nth-child(4) {
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

nav.show ul.main li:nth-child(5) {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

nav.show ul.main li:nth-child(6) {
  -webkit-transition-delay: 0.9s;
  -o-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

nav.show ul.main li:nth-child(7) {
  -webkit-transition-delay: 1.05s;
  -o-transition-delay: 1.05s;
  transition-delay: 1.05s;
}

nav.show ul.main li:nth-child(8) {
  -webkit-transition-delay: 1.2s;
  -o-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

nav.show ul.main li:nth-child(9) {
  -webkit-transition-delay: 1.35s;
  -o-transition-delay: 1.35s;
  transition-delay: 1.35s;
}

nav.show .about,
nav.show .social,
nav.show ul.sub {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  -webkit-transition-delay: 0.85s;
  -o-transition-delay: 0.85s;
  transition-delay: 0.85s;
}

nav ul.main {
  list-style-type: none;
  padding-left: 0;
}

nav ul.main li {
  margin-bottom: 25px;
  transform: translateX(40px);
  opacity: 0;
  transition: all 0.3s ease;
  color: #ffffff;
  text-transform: uppercase;
}

nav ul.main li:last-of-type {
  margin-bottom: 0px;
}

nav ul.main li a {
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  display: inline-block;
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

nav ul.main li a span {
  color: #b7ac7f;
}

nav ul.sub {
  list-style-type: none;
  margin-top: 40px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

nav ul.sub li {
  margin-bottom: 10px;
}

nav ul.sub li:last-of-type {
  margin-bottom: 0px;
}

nav ul.sub li a {
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 0.9rem;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.navAgain {
  background-color: black;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  width: 600px;
  height: 100%;
  padding: 90px 100px 60px 50px;
  overflow-y: auto;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: transform 0s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: transform 0s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: -webkit-transform 0s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@media (max-width: 991px) {
  .navAgain {
    margin-top: 60px;
    width: 100%;
    padding-top: 50px;
    padding-left: 40px;
    padding-right: 30px;
  }
}

.navAgain.show {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.navAgain.show ul.main li {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
}

.navAgain.show ul.main li:nth-child(1) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.navAgain.show ul.main li:nth-child(2) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.navAgain.show ul.main li:nth-child(3) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.navAgain.show ul.main li:nth-child(4) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.navAgain.show ul.main li:nth-child(5) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.navAgain.show ul.main li:nth-child(6) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.navAgain.show ul.main li:nth-child(7) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.navAgain.show ul.main li:nth-child(8) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.navAgain.show ul.main li:nth-child(9) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.navAgain.show .about,
.navAgain.show .social,
.navAgain.show ul.sub {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.navAgain ul.main {
  list-style-type: none;
  padding-left: 0;
}

.navAgain ul.main li {
  margin-bottom: 25px;
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
  opacity: 0;
  -webkit-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
  color: #ffffff;
}

.navAgain ul.main li:last-of-type {
  margin-bottom: 0px;
}

.navAgain ul.main li a {
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  -webkit-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}

.navAgain ul.main li a span {
  color: #b7ac7f;
}

.navAgain ul.sub {
  list-style-type: none;
  margin-top: 40px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  -webkit-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}

.navAgain ul.sub li {
  margin-bottom: 10px;
}

.navAgain ul.sub li:last-of-type {
  margin-bottom: 0px;
}

.navAgain ul.sub li {
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 0.9rem;
  text-decoration: none;
  -webkit-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}

.navAgain li .header__text {
  font-size: 40px !important;
  line-height: 53px;
  color: var(--color-link) !important;
  font-weight: 300 !important;
  text-transform: none !important;
}

@media (max-width: 991px) {
  .navAgain li .header__text {
    font-size: 30px !important;
    line-height: 40px;
  }
}
.navAgain li .middle__text {
  font-size: 30px !important;
  line-height: 50px;
  font-weight: 300 !important;
  text-transform: none !important;
}

@media (max-width: 991px) {
  .navAgain li a.middle__text {
    font-size: 20px !important;
    line-height: 30px;
  }
}
.navAgain li .small__text {
  font-size: 20px !important;
  font-weight: 100 !important;
  text-transform: none !important;
}

@media (max-width: 991px) {
  .navAgain li .small__text {
    font-size: 16px !important;
    font-family: "Open Sans", sans-serif;
  }
}
.navAgain li a.mail {
  font-size: 30px !important;
  font-weight: 100 !important;
  text-transform: none !important;
  position: relative !important;
  padding-left: 55px;
}

@media (max-width: 991px) {
  .navAgain li a.mail {
    font-size: 20px !important;
  }
}
.navAgain li a.telegram {
  font-size: 30px !important;
  font-weight: 100 !important;
  text-transform: none !important;
  position: relative !important;
  padding-left: 55px;
}

@media (max-width: 991px) {
  .navAgain li a.telegram {
    font-size: 20px !important;
  }
}
.navAgain li a.skype {
  font-size: 30px !important;
  font-weight: 100 !important;
  text-transform: none !important;
  position: relative !important;
  padding-left: 55px;
}

@media (max-width: 991px) {
  .navAgain li a.skype {
    font-size: 20px !important;
  }
}
.navAgain li a.mail::before {
  content: "";
  background-image: url(../img/mail.svg);
  position: absolute;
  width: 32px;
  height: 32px;
  left: 0;
  bottom: 8px;
  background-size: 32px 32px;
}

.navAgain li a.telegram::before {
  content: "";
  background-image: url(../img/letter.svg);
  position: absolute;
  width: 32px;
  height: 28px;
  left: 0;
  bottom: 8px;
  background-size: 32px 28px;
}

.navAgain li a.skype::before {
  content: "";
  background-image: url(../img/skype.svg);
  position: absolute;
  width: 32px;
  height: 32px;
  left: 0;
  bottom: 8px;
  background-size: 32px 32px;
}

@media (max-width: 991px) {
  .navAgain li a.mail::before {
    bottom: unset;
  }
  .navAgain li a.telegram::before {
    bottom: unset;
  }
  .navAgain li a.skype::before {
    bottom: unset;
  }
}
/*  OVERLAY
========================================== */
@media (max-width: 991px) {
  .overlay2 {
    top: 60px !important;
  }
}
.overlay,
.overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: rgba(197, 197, 197, 0.4588235294);
  opacity: 0;
  visibility: hidden;
}

.overlay.show,
.overlay2.show {
  opacity: 0.5;
  visibility: visible;
  background-color: #b2b2b2;
  box-shadow: inset 0 0 50px 0px hsla(0, 0%, 100%, 0.9);
}

.button {
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.25, 0.1, 0.2, 1);
  border: 2px solid var(--color-link);
  border-color: var(--color-link) !important;
  padding: 8px 8px;
  color: white;
}

.btn-animation:before,
.btn-animation:after {
  position: absolute;
  content: "";
  display: block;
}

.btn-animation:before {
  top: -120px;
  left: 50px;
  z-index: -1;
  width: -webkit-calc(100% + 140px);
  width: calc(100% + 140px);
  height: 100px;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.1, 0.2, 1);
  -o-transition: all 0.4s cubic-bezier(0.25, 0.1, 0.2, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.1, 0.2, 1);
  -webkit-transform: skew(70deg);
  -ms-transform: skew(70deg);
  transform: skew(70deg);
  background: var(--color-link);
}

.btn-animation:after {
  top: 0;
  left: 0;
  z-index: -2;
  width: -webkit-calc(100% - 2px);
  width: calc(100% - 2px);
  height: -webkit-calc(100% - 2px);
  height: calc(100% - 2px);
}

.btn-animation:hover:before {
  left: -50px;
  top: -20px;
}

/*  FONTS
========================================== */
/*  MIXINS
========================================== */
.carousel .carousel-indicators {
  z-index: 2;
  height: 40px;
  justify-content: flex-end;
  width: 100%;
  margin: 0px;
  top: -70px;
}

.carousel .carousel-indicators .active {
  background-color: var(--color-link);
}

.carousel .carousel-indicators li {
  background-color: black;
  cursor: pointer;
  margin: 0 15px;
  width: 10px;
  height: 10px;
}

html {
  background-color: white;
}

html::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

html::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

html::-webkit-scrollbar-thumb {
  background-color: black;
}

body.overflow {
  overflow: hidden;
}

section,
.section {
  padding-top: 90px;
  z-index: 5;
  max-width: 1720px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  min-height: -webkit-calc(100vh - 157px);
  min-height: calc(100vh - 157px);
}

@media (max-width: 991px) {
  section,
  .section {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 60px;
  }
}
section__header,
.section__header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 90px;
}

@media (max-width: 991px) {
  section__header,
  .section__header {
    top: 60px;
  }
}
section__descr,
.section__descr {
  background-color: black;
  color: white;
  font-size: 21px;
  font-weight: 300;
}

section__descr .descr__container,
.section__descr .descr__container {
  max-width: 1720px;
  margin: 0 auto;
  padding-left: 100px;
  padding-right: 100px;
}

@media (max-width: 991px) {
  section__descr .descr__container,
  .section__descr .descr__container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
section__descr__header,
.section__descr__header {
  font-size: 30px;
  color: var(--color-link);
  padding-top: 50px;
  padding-bottom: 15px;
}

@media (max-width: 991px) {
  section__descr__header,
  .section__descr__header {
    font-size: 20px;
    padding-top: 30px;
  }
}
section__descr__content,
.section__descr__content {
  font-family: "Open Sans", sans-serif;
  line-height: 2;
  padding-bottom: 48px;
}

@media (max-width: 991px) {
  section__descr__content,
  .section__descr__content {
    font-size: 16px;
  }
}
section__image,
.section__image {
  background-color: black;
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}

@media (max-width: 991px) {
  section__image,
  .section__image {
    height: 200px;
  }
}
section__image .image.eaist,
.section__image .image.eaist {
  background-image: url(../img/eaist_main2.jpg);
}

section__image .image.park,
.section__image .image.park {
  background-image: url(../img/parking_main2.jpg);
}

section__image .image.asud,
.section__image .image.asud {
  background-image: url(../img/asudic_main2.jpg);
}

section__image .image.data,
.section__image .image.data {
  background-image: url(../img/datacenter_main2.jpg);
}

section__image .image.ventra,
.section__image .image.ventra {
  background-image: url(../img/ventra_main2.jpg);
}

section__image .image.moscow,
.section__image .image.moscow {
  background-image: url(../img/mosparking_main2.jpg);
}

section__image .image,
.section__image .image {
  background-image: url(../img/pricing_main2.jpg);
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100%;
  background-position: center;
  background-repeat: none;
}

@media (max-width: 991px) {
  section__image .image,
  .section__image .image {
    height: 200px;
    -webkit-background-size: cover;
    background-size: cover;
  }
}
section__image__header,
.section__image__header {
  color: white;
  position: absolute;
  top: 22%;
  font-size: 60px;
  font-weight: 300;
}

section__image__projectlink,
.section__image__projectlink {
  color: white;
  position: absolute;
  top: 28%;
  font-size: 30px;
  font-weight: 300;
}

section__image__customerlink,
.section__image__customerlink {
  color: var(--color-link);
  position: absolute;
  top: 35%;
  font-size: 30px;
  font-weight: 300;
}

@media (max-width: 991px) {
  section__image__header,
  .section__image__header {
    font-size: 30px;
    top: 19%;
    left: 30px;
  }
}
.eaist .container-fluid,
.park .container-fluid,
.moscow .container-fluid,
.ventra .container-fluid,
.data .container-fluid,
.asud .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.portfolio {
  margin-top: 67px;
}

.portfolio .container-fluid {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.portfolio__image {
  flex: calc(50% - 40px);
  position: relative;
  text-align: center;
}

.portfolio__image .descr {
  position: absolute;
  color: white;
  text-align: center;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  pointer-events: none;
  padding-left: 15%;
  padding-right: 15%;
  opacity: 0;
  background-color: black;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

@media (max-width: 991px) {
  .portfolio__image .descr {
    pointer-events: all;
  }
}
.portfolio__image .descr .btn {
  font-weight: 300;
  margin-top: 20px;
  border: 1px solid white;
  display: none;
}

@media (max-width: 991px) {
  .portfolio__image .descr .btn {
    display: block;
  }
}
.portfolio__image .descr__header {
  font-size: 30px;
}

@media (max-width: 991px) {
  .portfolio__image .descr__header {
    font-size: 20px;
  }
}
.portfolio__image .descr__content {
  font-size: 20px;
  color: var(--color-link);
  margin-top: 24px;
  font-family: "Open Sans", sans-serif;
}

@media (max-width: 991px) {
  .portfolio__image .descr__content {
    font-size: 16px;
  }
}
.portfolio__image:hover .descr {
  opacity: 1;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

@media (max-width: 991px) {
  .portfolio__image {
    -webkit-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }
}
.portfolio__image a {
  width: 100%;
}

.portfolio__image a img {
  width: 100%;
}

.pricing .image {
  background-image: url(../img/pricing_main2.jpg);
}

@media (max-width: 991px) {
  .pricing .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}
header {
  z-index: 10;
}

.pointer {
  cursor: pointer;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 90px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 30px;
  background-color: #fff;
  z-index: 10;
}

@media (max-width: 991px) {
  .header {
    height: 60px;
  }
}
.header__mob {
  color: white;
  background-color: black;
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  height: 100vh;
  padding-top: 30px;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
}

.header__mob img {
  float: right;
  margin-right: 30px;
}

.header__mob ul {
  list-style-type: none;
  padding-left: 27px;
  padding-right: 56px;
  padding-top: 20px;
}

.header__mob ul li {
  margin-top: 40px;
}

.header__mob ul li a {
  color: inherit;
}

.header .header__hamb {
  display: none;
}

.header .header__hamb img {
  cursor: pointer;
}

@media (max-width: 1199px) {
  .header .header__hamb {
    display: block;
  }
}
.header__logo img {
  height: 28px;
}

@media (max-width: 1199px) {
  .header__list {
    display: none;
  }
}
.header__list ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 40px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  text-transform: uppercase;
  align-items: center;
}
.header__list ul li {
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
}
.header__list ul li.active {
  border-bottom-color: var(--color-main);
}

.header__mob ul li:last-child {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  border: solid 2px var(--color-link);
  color: var(--color-link);
  font-weight: 400;
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  border: solid 2px var(--color-link);
  position: relative;
  overflow: hidden;
  color: var(--color-link);
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.btn-animation:before,
.btn-animation:after {
  position: absolute;
  content: "";
  display: block;
}

.btn-animation:before {
  top: -120px;
  left: 50px;
  z-index: -1;
  width: -webkit-calc(100% + 140px);
  width: calc(100% + 140px);
  height: 100px;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.1, 0.2, 1);
  -o-transition: all 0.4s cubic-bezier(0.25, 0.1, 0.2, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.1, 0.2, 1);
  -webkit-transform: skew(70deg);
  -ms-transform: skew(70deg);
  transform: skew(70deg);
  background: var(--color-link);
}

.btn-animation:after {
  top: 0;
  left: 0;
  z-index: -2;
  width: -webkit-calc(100% - 2px);
  width: calc(100% - 2px);
  height: -webkit-calc(100% - 2px);
  height: calc(100% - 2px);
}

.btn-animation:hover {
  text-decoration: none;
  color: white;
}

.btn-animation:hover:before {
  left: -50px;
  top: -20px;
}

.header__list ul li a {
  color: inherit;
}

.header__list ul li a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.header__list .selected {
  color: var(--color-link);
}

.services {
  margin-top: 67px;
  position: relative;
}

.services .services__big__text {
  font-size: 59px;
  font-weight: 300;
}

@media (max-width: 991px) {
  .services .services__big__text {
    font-size: 30px;
  }
}
.services .services__items {
  margin-top: 70px;
}

@media (max-width: 991px) {
  .services .services__items {
    margin-top: 10px;
  }
}
@media (max-width: 991px) {
  .services .services__items .services__item {
    margin-top: 30px;
  }
}
.services .services__items .services__item__info__header {
  font-size: 30px;
}

@media (max-width: 991px) {
  .services .services__items .services__item__info__header {
    font-size: 20px;
  }
}
.services .services__items .services__item__info__header img {
  width: 32px;
  height: 32px;
}

.services .services__items .services__item__info__header__name {
  margin-left: 9px;
  display: inline;
  font-weight: 300;
}

.services .services__items .services__item__info__list {
  font-family: "Open Sans", sans-serif;
  margin-left: 50px;
  margin-top: 15px;
  font-size: 20px;
}

@media (max-width: 991px) {
  .services .services__items .services__item__info__list {
    font-size: 16px;
    margin-top: 15px;
  }
}
.services .services__items .services__item__info__list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.services .services__items .services__item__info__list ul li {
  margin-bottom: 26px;
}

@media (max-width: 991px) {
  .services .services__items .services__item__info__list ul li {
    margin-bottom: 15px;
  }
}
.services .services__items .services__item__info__list ul li .list__item__content {
  opacity: 0.5;
  font-size: 16px;
}

@media (max-width: 991px) {
  .services .services__items .services__item__info__list ul li .list__item__content {
    font-size: 12px;
  }
}
.pricing,
.portfolio {
  font-weight: 300;
}

.pricing__content,
.portfolio__content {
  padding-top: 70px;
}

@media (max-width: 991px) {
  .pricing__content,
  .portfolio__content {
    padding-top: 10px;
  }
}
.pricing__content .pricing__icons,
.portfolio__content .pricing__icons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pricing__content .pricing__icons__item,
.portfolio__content .pricing__icons__item {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-weight: 500;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  .pricing__content .pricing__icons__item,
  .portfolio__content .pricing__icons__item {
    -webkit-box-flex: 50%;
    -webkit-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    margin-bottom: 40px;
    font-size: 16px;
  }
}
.pricing__content .pricing__icons__item .item__icon,
.portfolio__content .pricing__icons__item .item__icon {
  width: 100px;
  height: 100px;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pricing__content .pricing__icons__item .item__icon img,
.portfolio__content .pricing__icons__item .item__icon img {
  padding-bottom: 35px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.pricing__table__title {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  padding-top: 30px;
  padding-bottom: 10px;
  margin: 0 auto;
}

.pricing__table,
.portfolio__table {
  text-align: center;
  max-width: 1520px;
  font-weight: 300;
  margin: 0 auto;
}

.pricing__whatsincluded__title {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  padding-top: 50px;
  padding-bottom: 10px;
  margin: 0 auto;
}

.pricing__whatsincluded__description {
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 auto;
}

.pricing__whatsincluded__list {
  font-size: 20px;
  padding-bottom: 100px;
}

@media (max-width: 991px) {
  .pricing__table,
  .portfolio__table {
    padding-top: 0;
  }
}
@media (max-width: 991px) {
  .pricing__table,
  .portfolio__table {
    display: none;
  }
}
.pricing__table.mobile,
.portfolio__table.mobile {
  display: none;
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  padding-bottom: 10px;
}

@media (max-width: 991px) {
  .pricing__table.mobile,
  .portfolio__table.mobile {
    display: block;
  }
}
.pricing__table .table__item .item,
.portfolio__table .table__item .item {
  margin-bottom: 20px;
}

.pricing__table .table__item .item__header,
.portfolio__table .table__item .item__header {
  background-color: var(--color-link);
  height: 40px;
  color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 300;
}

.pricing__table .table__header,
.portfolio__table .table__header {
  padding-left: 160px;
  padding-right: 160px;
  background-color: var(--color-link);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  color: white;
  height: 60px;
  font-size: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pricing__table .table__row,
.portfolio__table .table__row {
  padding-left: 160px;
  padding-right: 160px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  height: 60px;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
}

.pricing__table .table__row:nth-child(2n+1),
.portfolio__table .table__row:nth-child(2n+1) {
  background-color: #f5f5f5;
}

.pricing__table .table__row .item,
.portfolio__table .table__row .item {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.eaist .section__image__header,
.park .section__image__header,
.moscow .section__image__header,
.ventra .section__image__header,
.data .section__image__header,
.asud .section__image__header {
  top: 13%;
  font-weight: 300;
  padding-right: 30px;
}

@media (max-width: 991px) {
  .eaist .section__image__header,
  .park .section__image__header,
  .moscow .section__image__header,
  .ventra .section__image__header,
  .data .section__image__header,
  .asud .section__image__header {
    top: 7%;
  }
}
.eaist__image__header,
.park__image__header,
.moscow__image__header,
.ventra__image__header,
.data__image__header,
.asud__image__header {
  top: 28%;
  width: 60%;
}

.eaist__slider,
.park__slider,
.moscow__slider,
.ventra__slider,
.data__slider,
.asud__slider {
  padding-top: 80px;
  padding-bottom: 100px;
}

@media (max-width: 991px) {
  .eaist__slider,
  .park__slider,
  .moscow__slider,
  .ventra__slider,
  .data__slider,
  .asud__slider {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
.eaist__slider__items,
.park__slider__items,
.moscow__slider__items,
.ventra__slider__items,
.data__slider__items,
.asud__slider__items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 30px;
}

@media (max-width: 991px) {
  .eaist__slider__items,
  .park__slider__items,
  .moscow__slider__items,
  .ventra__slider__items,
  .data__slider__items,
  .asud__slider__items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.eaist__slider__item,
.park__slider__item,
.moscow__slider__item,
.ventra__slider__item,
.data__slider__item,
.asud__slider__item {
  max-width: 55%;
}

@media (max-width: 991px) {
  .eaist__slider__item,
  .park__slider__item,
  .moscow__slider__item,
  .ventra__slider__item,
  .data__slider__item,
  .asud__slider__item {
    max-width: 100%;
    margin-bottom: 40px;
  }
}
.eaist__slider__item__header,
.park__slider__item__header,
.moscow__slider__item__header,
.ventra__slider__item__header,
.data__slider__item__header,
.asud__slider__item__header {
  color: var(--color-link);
  margin-bottom: 15px;
  font-weight: 300;
}

@media (max-width: 991px) {
  .eaist__slider__item__header,
  .park__slider__item__header,
  .moscow__slider__item__header,
  .ventra__slider__item__header,
  .data__slider__item__header,
  .asud__slider__item__header {
    font-size: 20px;
  }
}
.eaist__slider__item__content,
.park__slider__item__content,
.moscow__slider__item__content,
.ventra__slider__item__content,
.data__slider__item__content,
.asud__slider__item__content {
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 2;
}

@media (max-width: 991px) {
  .eaist__slider__item__content,
  .park__slider__item__content,
  .moscow__slider__item__content,
  .ventra__slider__item__content,
  .data__slider__item__content,
  .asud__slider__item__content {
    font-weight: 100;
    font-size: 16px;
  }
}
.eaist__slider__item__content div,
.park__slider__item__content div,
.moscow__slider__item__content div,
.ventra__slider__item__content div,
.data__slider__item__content div,
.asud__slider__item__content div {
  margin-bottom: 15px;
}

.eaist__slider__dots,
.park__slider__dots,
.moscow__slider__dots,
.ventra__slider__dots,
.data__slider__dots,
.asud__slider__dots {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 20px;
}

@media (max-width: 991px) {
  .eaist__slider__dots,
  .park__slider__dots,
  .moscow__slider__dots,
  .ventra__slider__dots,
  .data__slider__dots,
  .asud__slider__dots {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
    margin-top: 0px;
  }
}
.eaist__slider__dots__item,
.park__slider__dots__item,
.moscow__slider__dots__item,
.ventra__slider__dots__item,
.data__slider__dots__item,
.asud__slider__dots__item {
  margin-left: 30px;
  position: relative;
  width: 10px;
  height: 10px;
  background-color: black;
}

.eaist__slider__dots__item.selected,
.park__slider__dots__item.selected,
.moscow__slider__dots__item.selected,
.ventra__slider__dots__item.selected,
.data__slider__dots__item.selected,
.asud__slider__dots__item.selected {
  background-color: var(--color-link);
}

.eaist__bottom__image,
.park__bottom__image,
.moscow__bottom__image,
.ventra__bottom__image,
.data__bottom__image,
.asud__bottom__image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.portfolio__buttons {
  padding-top: 60px;
  padding-bottom: 60px;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 30px;
  font-weight: 300;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .portfolio__buttons {
    padding-top: 30px;
    padding-bottom: 40px;
    font-size: 16px;
  }
}
.portfolio__buttons a {
  color: inherit;
}

.portfolio__buttons a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.portfolio__buttons a:nth-child(2) {
  color: var(--color-link);
}

.orange {
  color: var(--color-link);
}

.wework {
  padding-right: 100px;
  padding-left: 100px;
  margin-top: 67px;
}

@media (max-width: 991px) {
  .wework {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 30px;
  }
}
.wework__logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding-right: 42px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  .wework__logo img {
    width: 40px;
  }
}
@media (max-width: 991px) {
  .wework__logo {
    padding-right: 13px;
  }
}
.wework__logo::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: var(--color-link);
  right: 0;
}

.wework__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 45px;
}

.wework__row .wework__content {
  margin-left: 25px;
}

@media (max-width: 991px) {
  .wework__row .wework__content {
    margin-left: 13px;
  }
}
.wework__row .wework__content__header {
  font-size: 30px;
  font-weight: 300;
}

@media (max-width: 991px) {
  .wework__row .wework__content__header {
    font-size: 20px;
  }
}
.wework__row .wework__content__info {
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
}

@media (max-width: 991px) {
  .wework__row .wework__content__info {
    font-size: 16px;
  }
}
.tech {
  padding-right: 100px;
  padding-left: 100px;
  margin-top: 67px;
}

@media (max-width: 991px) {
  .tech {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 30px;
  }
}
.tech__info {
  font-size: 29px;
  font-weight: 300;
}

@media (max-width: 991px) {
  .tech__info {
    font-size: 16px;
  }
}
.tech__input {
  margin-top: 45px;
  position: relative;
}

.tech__input input {
  height: 60px;
  color: black;
  position: relative;
  font-size: 20px;
  padding-left: 22px;
  border: solid 2px #f5f5f5;
  outline: none;
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

@media (max-width: 991px) {
  .tech__input input {
    font-size: 16px;
  }
}
.tech__input input::-webkit-input-placeholder {
  color: black;
  font-size: 20px;
}

.tech__input input::-ms-input-placeholder {
  color: black;
  font-size: 20px;
}

.tech__input input::placeholder {
  color: black;
  font-size: 20px;
}

@media (max-width: 991px) {
  .tech__input input::-webkit-input-placeholder {
    font-size: 16px;
  }
  .tech__input input::-ms-input-placeholder {
    font-size: 16px;
  }
  .tech__input input::placeholder {
    font-size: 16px;
  }
}
.tech__input input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: var(--color-link);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 115, 0, 0.1882352941);
  box-shadow: 0 0 0 0.2rem rgba(255, 115, 0, 0.1882352941);
}

.tech__input::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background-image: url(../img/serach.svg);
  background-repeat: no-repeat;
  right: 0;
  top: 0;
  margin-top: 18px;
  margin-right: 26px;
}

.tech__items {
  position: relative;
  height: fit-content;
  margin-bottom: 30px;
}

.tech__items svg {
  width: 100%;
  height: 100%;
  margin: 10px;
}

/*@media (max-width: $mobileWidth) {*/
/*.tech__items {*/
/*-webkit-transform: translateX(-34px);*/
/*-ms-transform: translateX(-34px);*/
/*transform: translateX(-34px);*/
/*}*/
/*}*/
.tech__items .tech__item {
  font-size: 50px;
  position: absolute;
  white-space: nowrap;
}

@media (max-width: 991px) {
  .tech__items .tech__item {
    font-size: 24px;
  }
}
@-webkit-keyframes pointFour {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  25% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  75% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
}
@keyframes pointFour {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  25% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  75% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
}
@-webkit-keyframes pointFourRev {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  25% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  50% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  75% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
}
@keyframes pointFourRev {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  25% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  50% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  75% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
}
@-webkit-keyframes pointFourEight {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  17% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  34% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  51% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  68% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  85% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes pointFourEight {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  17% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  34% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  51% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  68% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  85% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
footer {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.2);
  bottom: 0;
  width: 100%;
  padding: 20px 40px;
  margin-top: 140px;
  z-index: 1;
  font-family: "Open Sans", sans-serif;
}
footer > div:first-child {
  color: var(--color-link);
}

@media (max-width: 991px) {
  footer {
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    line-height: 20px;
    height: auto;
    margin-top: 20px;
  }
}
.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
  margin-left: 10px;
  width: 100%;
  max-width: 100%;
}

.cd-words-wrapper em {
  font-style: normal;
}

.cd-words-wrapper b {
  font-weight: 300;
}

.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: -15px;
  top: 0;
}

.cd-words-wrapper b.is-visible {
  position: relative;
}

.no-js .cd-words-wrapper b {
  opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

.cd-headline.rotate-2 .cd-words-wrapper {
  -webkit-perspective: 300px;
  perspective: 300px;
}

.cd-headline.rotate-2 i,
.cd-headline.rotate-2 em {
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cd-headline.rotate-2 b {
  opacity: 0;
}

.cd-headline.rotate-2 i {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(-20px) rotateX(90deg);
  transform: translateZ(-20px) rotateX(90deg);
  opacity: 0;
}

.is-visible .cd-headline.rotate-2 i {
  opacity: 1;
}

.cd-headline.rotate-2 i.in {
  -webkit-animation: cd-rotate-2-in 0.4s forwards;
  animation: cd-rotate-2-in 0.4s forwards;
}

.cd-headline.rotate-2 i.out {
  -webkit-animation: cd-rotate-2-out 0.4s forwards;
  animation: cd-rotate-2-out 0.4s forwards;
}

.cd-headline.rotate-2 em {
  -webkit-transform: translateZ(20px);
  transform: translateZ(20px);
}

.no-csstransitions .cd-headline.rotate-2 i {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 0;
}

.no-csstransitions .cd-headline.rotate-2 i em {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.no-csstransitions .cd-headline.rotate-2 .is-visible i {
  opacity: 1;
}

@-webkit-keyframes cd-rotate-2-in {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(90deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(-10deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(0deg);
  }
}
@keyframes cd-rotate-2-in {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(90deg);
    transform: translateZ(-20px) rotateX(90deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(-10deg);
    transform: translateZ(-20px) rotateX(-10deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(0deg);
    transform: translateZ(-20px) rotateX(0deg);
  }
}
@-webkit-keyframes cd-rotate-2-out {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(-100deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(-90deg);
  }
}
@keyframes cd-rotate-2-out {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(0);
    transform: translateZ(-20px) rotateX(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(-100deg);
    transform: translateZ(-20px) rotateX(-100deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(-90deg);
    transform: translateZ(-20px) rotateX(-90deg);
  }
}
.telegram {
  vertical-align: middle;
  box-sizing: border-box;
  width: 32px;
  height: 28px;
}

svg g text {
  transition: opacity 0.3s;
}

.filtered {
  opacity: 0.1;
}

@media (max-width: 991px) {
  .mobile-hide {
    display: none !important;
  }
}